<script>
export default {
    props: {
        maximizeOnSm: {
            type: Boolean,
            default: false
        },
        divideY: {
            type: Boolean,
            default: true
        },
    }
}
</script>


<template>
    <div :class="[maximizeOnSm ? 'rounded-none' : 'rounded-xl', divideY ? 'divide-y divide-zinc-200/75 dark:divide-white/[7.5%]' : '']" class="bg-card-light dark:bg-card-dark border border-zinc-800/10 dark:border-none rounded-xl">
        <slot />
    </div>
</template>
